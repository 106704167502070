<template>
  <b-sidebar
    id="sidebar-address-edit"
    ref="sidebarAddressEdit"
    v-model="isShowSidebar"
    right
    backdrop
    shadow
    no-header
    width="85vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col cols="12" class="text-filter">Edit Address Information</b-col>
      </b-row>
      <div class="my-3">
        <b-row>
          <b-col cols="12" md="6">
            <InputSelect
              title="Country"
              name="Name-Title"
              :options="Country"
              v-model="form.country"
              valueField="englishName"
              textField="englishName"
              isRequired
              @onDataChange="onCountryChange"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <div class="break-normal"></div>
          <template v-if="form.country == 'ประเทศไทย' || form.country == ''">
            <b-col cols="12" md="6">
              <InputText
                textFloat="Address"
                placeholder="Address"
                type="text"
                name="home_address"
                isRequired
                :v="$v.form.home_address"
                :isValidate="$v.form.home_address.$error"
                v-model="form.home_address"
              />
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Building / Village Name"
                placeholder="Building / Village Name"
                type="text"
                name="Building"
                v-model="form.town"
              />
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Alley / Soi"
                placeholder="Alley / Soi"
                type="text"
                name="alley"
                v-model="form.alley"
              />
            </b-col>

            <b-col cols="12" md="6">
              <InputText
                textFloat="Road"
                placeholder="Road"
                type="text"
                name="road"
                v-model="form.road"
              />
            </b-col>
            <b-col cols="12" md="6">
              <InputSelect
                title="Province"
                name="Province"
                :options="addressOptions.province.field_choices"
                v-model="form.province"
                valueField="name"
                textField="name"
                isRequired
                @onDataChange="
                  handleChange(
                    $event,
                    addressOptions.province.field_choices,
                    'district',
                    'subdistrict'
                  )
                "
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option
                    v-if="form.province == null"
                    value=""
                    disabled
                    >Please Select
                  </b-form-select-option>
                  <b-form-select-option v-else value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect>
            </b-col>
            <b-col cols="12" md="6">
              <InputSelect
                title="District"
                name="District"
                :options="[]"
                value=""
                isRequired
                :disabled="true"
                v-if="loading.district"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Loading...
                  </b-form-select-option>
                </template>
              </InputSelect>
              <InputSelect
                v-else
                title="District"
                name="District"
                :options="options.district.field_choices || []"
                v-model="form.district"
                valueField="name"
                textField="name"
                isRequired
                :v="$v.form.district"
                :isValidate="$v.form.district.$error"
                @onDataChange="
                  handleChange(
                    $event,
                    options.district.field_choices,
                    'subdistrict',
                    ''
                  )
                "
                :disabled="form.province == ''"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect>
            </b-col>
            <b-col cols="12" md="6">
              <InputSelect
                title="Subdistrict"
                name="Subdistrict"
                :options="[]"
                value=""
                :disabled="true"
                v-if="loading.subdistrict"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Loading...
                  </b-form-select-option>
                </template>
              </InputSelect>
              <InputSelect
                v-else
                title="Subdistrict"
                name="subdistrict"
                :options="options.subdistrict.field_choices || []"
                v-model="form.subdistrict"
                valueField="name"
                textField="name"
                isRequired
                :v="$v.form.subdistrict"
                :isValidate="$v.form.subdistrict.$error"
                @onDataChange="
                  handleChange(
                    $event,
                    options.subdistrict.field_choices,
                    'zip_code'
                  )
                "
                :disabled="!form.district"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect>
              <!-- <InputText
              textFloat="Sub-district"
              placeholder="Sub-district"
              type="text"
              name="subdistrict"
              v-model=""
            /> -->
            </b-col>

            <b-col cols="12" md="6">
              <InputText
                textFloat="Zip Code"
                placeholder="Zip Code"
                type="number"
                name="zip_code"
                v-model="form.zip_code"
                disabled
                @onKeypress="handleMaxlength($event)"
              />
            </b-col>
          </template>
          <template v-else>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Address"
                placeholder="Address"
                type="text"
                name="home_address"
                isRequired
                :v="$v.form.home_address"
                :isValidate="$v.form.home_address.$error"
                v-model="form.home_address"
              />
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Building / Village Name"
                placeholder="Building / Village Name"
                type="text"
                name="Building"
                v-model="form.town"
              />
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Alley / Soi"
                placeholder="Alley / Soi"
                type="text"
                name="alley"
                v-model="form.alley"
              />
            </b-col>

            <b-col cols="12" md="6">
              <InputText
                textFloat="Road"
                placeholder="Road"
                type="text"
                name="road"
                v-model="form.road"
              />
            </b-col>
            <!-- <InputTextArea
                textFloat="Address"
                placeholder="Full Address."
                type="text"
                name="full-address"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 500);'"
                v-model="form.home_address"
                isRequired
                :v="$v.form.home_address"
                :isValidate="$v.form.home_address.$error"
              /> -->
            <b-col cols="12" md="6">
              <InputText
                textFloat="Province"
                placeholder="Province"
                type="text"
                name="fore_province"
                v-model="form.province"
              />
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="District"
                placeholder="District"
                type="text"
                name="fore_district"
                v-model="form.district"
              />
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Subdistrict"
                placeholder="Subdistrict"
                type="text"
                name="fore_subdistrict"
                v-model="form.subdistrict"
              />
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Zip Code"
                placeholder="Zip Code"
                type="text"
                name="fore_zip_code"
                v-model="form.zip_code"
              />
            </b-col>
          </template>
        </b-row>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required, requiredIf } from "vuelidate/lib/validators";
import Country from "@/assets/lang/country.json";
export default {
  validations() {
    return {
      form: {
        home_address: {
          required,
        },
        province: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        district: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        subdistrict: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
      },
    };
  },
  components: {
    InputText,
  },
  props: {
    formMain: {
      required: true,
      type: Object,
    },
    addressOptions: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      form: { ...this.formMain },
      options: { ...this.addressOptions },
      prefixOption: [],
      loading: {
        district: false,
        subdistrict: false,
      },
      Country: Country,
    };
  },
  methods: {
    handleMaxlength(e) {
      if (e.target.value.length == 5) {
        return e.preventDefault();
      }
    },

    async show() {
      this.form = JSON.parse(JSON.stringify(this.formMain));

      this.form.country = this.form.country || "ประเทศไทย";
      this.form.province = this.form.province || "";
      this.form.district = this.form.district || "";
      this.form.subdistrict = this.form.subdistrict || "";
      try {
        if (this.form.province) {
          await this.handleChange(
            this.form.province,
            this.addressOptions.province.field_choices,
            "district",
            "subdistrict",
            false
          );
        }
        if (this.form.district) {
          await this.handleChange(
            this.form.district,
            this.addressOptions.district.field_choices,
            "subdistrict",
            "sub_district",
            false
          );
        }
      } catch (error) {}
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submitAction() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.$emit("updateAddress", this.form);
      this.hide();
    },
    async handleChange(val, field, $set, $set2, clear = true) {
      let payload = field.find((el) => el.name == val);
      if ($set == "zip_code") {
        this.form[$set] = payload ? payload.zip_code : "";
      } else {
        this.loading[$set] = true;
        const result = await this.axios(
          `/setting/Getsubaddress/${payload.id}/${$set == "district" ? 1 : 2}`
        );

        this.$nextTick(() => {
          this.options[$set].field_choices = result.data.detail[$set];

          if (clear) {
            this.form[$set] = "";
            this.form.zip_code = "";
            if ($set2) {
              this.form[$set2] = "";
              this.options[$set2].field_choices = result.data.detail[$set2];
            }
          }
          this.loading[$set] = false;
        });
      }
    },
    onCountryChange() {
      this.form = {
        ...this.form,
        home_address: "",
        town: "",
        alley: "",
        road: "",
        province: "",
        district: "",
        subdistrict: "",
        zip_code: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
</style>
